<template>
  <div v-if="!isLoading" id="activation">
    <header-service
      :service="service"
      currentTab="Ativação"
      :isSaving="!isLoading && isSaving"
      @saving="save()"
    ></header-service>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8">
        
        <u-portlet title="Canais de ativação do serviço (Campanha)">
          <div class="col-md-12">
            <div class="form-row">
              <div v-for="mediaChannel in service.media_channels" :key="`media_channel-${mediaChannel.id}`" class="form-group col-md-4">
                <div class="mb-2">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                      name="media_channels[]"
                      :disabled="inputDisable"
                      type="checkbox"
                      class="custom-control-input"
                      :id="`media_channel_${mediaChannel.id}`"
                      :value="mediaChannel.id"
                      v-model="savedMediaChannels"
                    >
                    <label class="custom-control-label" :for="`media_channel_${mediaChannel.id}`">
                      {{ mediaChannel.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </u-portlet>

        <u-portlet title="Canais de ativação do serviço (Pull)">
          <div class="col-md-12">
            <div class="form-row">
              <div v-for="(channel, index) in channels" :key="index" class="form-group col-md-2">
                <div class="mb-2">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                      :disabled="inputDisable"
                      type="checkbox"
                      class="custom-control-input"
                      :checked="check(channel.id)"
                      @click="toggleChannel($event.target)"
                      :id="`channel-${index}`"
                      :data-channelId="channel.id"
                    >
                    <label class="custom-control-label" :for="`channel-${index}`">
                      {{ channel.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-tabs v-if="activationChannels.length">
            <b-tab
              v-for="(channel, channelIndex) in activationChannels"
              :key="channelIndex"
              :title="getChannelName(channel.channel_id)"
              :active="navActive(channel.channel_id)">
                <input :disabled="inputDisable" v-if="channel.id" type="hidden" class="activation-channels-saved" :value="channel.id">
                <div class="form-row mt-3">
                  <div class="form-group col-md-12">
                    <label>Descrição/Script de Ativação</label>
                    <textarea :disabled="inputDisable" class="form-control mb-3" rows="5" v-model="activationChannels[channelIndex].description"></textarea>
                    <label class="btn btn-outline-primary btn-upload" :for="`channel-file-${channel.channel_id}`" title="Upload image file">
                      <input
                        :disabled="inputDisable"
                        type="file"
                        class="sr-only all-files"
                        @change="addFiles($event.target)" multiple
                        :id="`channel-file-${channel.channel_id}`"
                        :data-id="channel.channel_id"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff">
                          <span class="info-file" :id="`info-file-${channel.channel_id}`">Adicionar imagens</span>
                    </label>
                  </div>
                  <div v-if="channel.files.length" v-for="(file, fileIndex) in channel.files" :key="fileIndex" 
                    :id="`saved-file-${file.id}`" 
                    class="col-xl-3 col-lg-4 col-12 col-sm-6 mb-4 mr-4">
                    <div class="card b-0">
                      <div class="position-relative">
                        <img class="card-img-top" :src="file.url" :alt="file.original_name">
                        <span @click="deleteFile(channelIndex, fileIndex)" :data-id="`${file.id}`" class="link m-menu__link-badge pull-right">
                          <span class="m-badge m-badge--primary">
                            Excluir
                          </span>
                        </span>
                        <input v-if="file.id" type="hidden" class="channel-files-saved" :value="file.id" >
                      </div>
                    </div>
                  </div>
                </div>
            </b-tab>
          </b-tabs>
        </u-portlet>
      </div> 
    </div>
  </div>
</template>

<script>
import MenuService from '../components/MenuService';
import UPortlet from '@/components/UPortlet';
import HeaderService from '../components/HeaderService';
import ServiceService from '../services/ServiceService';
import ActivationService from '../services/ActivationService';

export default {
  name: 'Activation',
  components: {
    MenuService,
    HeaderService,
    UPortlet,
  },
  created() {
    this.getService();
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      service: {},
      serviceService: new ServiceService(),
      activationService: new ActivationService(),
      activationChannels: {},
      formData: new FormData(),
      inputDisable: !this.$authorization.can('service-update'),
      mediaChannelsService: [],
      savedMediaChannels: [],
      channels: [
        {
          id: 1,
          name: 'Web+Wap',
        },
        {
          id: 2,
          name: 'SMS',
        },
        {
          id: 3,
          name: 'App',
        },
      ],
      tabActive: 1,
    };
  },
  watch: {
    '$store.state.service.lock': function(newValue, oldValue){
      this.lockForm(newValue);
    }
  },
  methods: {
    getService() {
      this.serviceService.getService(this.$route.params.id)
        .then((response) => {
          this.service = response.data;
          this.activationChannels = response.data.activationChannels;
          this.isLoading = false;
          this.preventEditForm();
          this.setSavedMediaChannels();
        })
    },
    setSavedMediaChannels() {
      this.service.activation_media_channels.forEach((media) => {
        this.savedMediaChannels.push(media.id);
      })
    },
    save() {
      this.isSaving = true;
      this.setFormDataSaved();
      this.activationService.save(this.formData)
        .then((response) => {
          this.activationChannels = response.data;
          this.isSaving = false;
          swal('Sucesso!', 'Serviço atualizado com sucesso.', 'success');
          if (this.service.status_register === 'complete') {
            this.service.status_register = 'updating';
          }
        })
        .catch((error) => {
          this.isSaving = false;
          swal('Ops!', 'Dados Inválidos. Verifique os dados informados e tente novamente.', 'error');
        })
      this.clearData();
    },
    toggleChannel(element) {
      const channelId = parseInt(element.getAttribute('data-channelId'));

      if (!element.checked) {
        for (let i in this.activationChannels) {
          if (this.activationChannels.length && channelId === parseInt(this.activationChannels[i].channel_id)) {
            this.activationChannels.splice(i, 1);
          }
          if (this.activationChannels.length === 1) {
            element.checked = true;
          }
        }
        this.tabActive = 1;
      } else if (!this.check(channelId)) {
          this.activationChannels.push({
            channel_id: channelId,
            description: '',
            files: null,
            filesNew: [],
          });

          this.tabActive = channelId;
      }
    },
    getChannelName(id) {
      let channelName = '';

      this.channels.forEach((channel) => {
        if (channel.id === id) {
          channelName = channel.name;
        }
      })
      
      return channelName;
    },
    addFiles(element) {
      const qtdFiles = element.files.length;
      const id = element.getAttribute('data-id');
      const span = document.getElementById(`info-file-${id}`);
      if (qtdFiles) {
        for (let i = 0; i < qtdFiles; i++) {
          this.formData.append(`${id}[]`, element.files[i]);
        }
        span.innerHTML = `${qtdFiles} Arquivo${qtdFiles > 1 ? 's' : ''} selecionado`;
      } else {
        span.innerHTML = 'Adicionar imagens';
      }
    },
    setFormDataSaved() {
      this.formData.append('service_id', this.service.id);
      if (this.activationChannels.length) {
        this.formData.append('activations', JSON.stringify(this.activationChannels));
      }
      const savedChannels = document.querySelectorAll('.activation-channels-saved');
      savedChannels.forEach((channel, index) => {
        this.formData.append('savedChannels[]', channel.value);
      })
      const savedFiles = document.querySelectorAll('.channel-files-saved');
      savedFiles.forEach((file, index) => {
        this.formData.append('savedFiles[]', file.value);
      });

      this.formData.append('activation_media_channels', this.savedMediaChannels);
    },
    check(channelId) {
       return this.activationChannels.length && this.activationChannels.filter(channel => channel.channel_id == channelId).length
    },
    deleteFile(channelIndex, fileIndex) {
      this.activationChannels[channelIndex].files.splice(fileIndex, 1);
    },
    clearData() {
      this.formData = new FormData();
      document.querySelectorAll('.all-files').forEach((file) => {
        file.value = null;
      });
      document.querySelectorAll('.info-file').forEach((spam) => {
        spam.innerHTML = 'Adicionar imagens';
      });
    },
    navActive(item) {
      return item === this.tabActive;
    },

    lockForm(isLocked) {
      const form = $(this.$refs.form);

      if (isLocked) {
        form.find('input, textarea, select').prop('disabled', true);
      } else {
        form.find('input, textarea, select').prop('disabled', false);
      }
    },

    preventEditForm() {
      setTimeout(() => {
        if(this.service.status_register === 'complete') {
          this.$store.commit('setLock', true);
          this.lockForm(true);
        }
      }, 300)
    }
  }
}
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
</style>
