/* global axios */
export default class ActivationService {
  constructor() {
    this.axios = axios;
  }
  save(formData) {
    return this.axios.post('service/activation/store', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}
